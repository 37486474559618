import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDAlert from "../../../components/MDAlert";
import {CardHeader} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {
    Tabbar as DHXTabbar,
    Grid as DHXGrid,
    Toolbar as DHXToolbar,
    Layout as DHXLayout,
    Form as DHXForm,
    Window as DHXWindow,
    DataView as DHXDataview,
    message as DHXMessage,
} from "dhx-suite";
import {doorLockSetGridColumn} from "./componentsConfig/doorLockSetGridColumn";
import {toolbar2Config} from "./componentsConfig/toolbar2Config";
import {WindowFootAccept,WindowFootClear,WindowFootClose} from "./componentsConfig/windowConfig";
import {doorLockSetFormConfig} from "./componentsConfig/doorLockSetFormConfig";
import {hotelLockSetFormConfig} from "./componentsConfig/hotelLockSetFormConfig";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {deleteFormConfig} from "./componentsConfig/deleteFormConfig";
import {doorLockEventLogGridColumn} from "./componentsConfig/doorLockEventLogGridColumn";
import './style.css';
import Progress from '../../../assets/images/icons8-iphone-spinner.gif';
import axios from "axios";
import api_url from "../../../context/UrlContext";
import Image_error from "../../../assets/images/error.png";

const AccessControlBasicSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXAccCtlTabLayout = useRef(null);

    let isNeedFirstChangeEvent_doorLockSetForm = false;

    useEffect(() => {

        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>請耐心等候</span></div>",
            css: "dhxProgressWindow1"
        });

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:23},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        let dhxAccCtlTabLayout = new DHXLayout(DHXAccCtlTabLayout.current,{
            type: "line",
            rows:[
                {
                    id: "roomNumberList",
                    resizable: true,
                    height: "400px"
                },
                {
                    id: "accCtlSetToolbar",
                    css:"accCtlSetToolbar",
                },
            ]
        });

        let roomNumberListLayout = new DHXLayout(dhxAccCtlTabLayout.getCell("roomNumberList"),{
            rows:[
                {id:"roomNumberListToolbar", height: "content", gravity: false},
                {id: "roomNumberList"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    axios.post(apiServer+"/accAndDev/loadDoorLockStatusList", {
                        itemId1: hotel
                    },headers).then((response) =>{
                        if(response.status === 200){
                            console.log(response);
                            dhxDataview.data.removeAll();
                            dhxDataview.data.parse(response.data);
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                case "sync":

                    axios.get(apiServer+"/accAndDev/type2",headers)
                        .then((response) =>{
                            console.log(response);
                            // dhxDataview.data.removeAll();
                            // dhxDataview.data.parse(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    // dhxProgressWindow1.show()
                    // axios.post(apiServer+"/accAndDev/syncDoorLockStatus",{itemId1:hotel},headers)
                    //     .then((response) =>{
                    //         if(response.status === 200){
                    //             if(response.data[0].result === 1){
                    //                 DHXMessage({
                    //                     text: "同步完成",
                    //                     icon:"dxi dxi-close",
                    //                     css: "dhx_message--success",
                    //                     expire:5000
                    //                 });
                    //
                    //                 axios.post(apiServer+"/accAndDev/loadDoorLockStatusList", {
                    //                     itemId1: hotel
                    //                 },headers).then((response) =>{
                    //                     if(response.status === 200) {
                    //                         if (response.data.length !== 0) {
                    //                             dhxDataview.data.removeAll();
                    //                             dhxDataview.data.parse(response.data);
                    //                         }
                    //                         setTimeout(()=>{
                    //                             dhxProgressWindow1.hide();
                    //                         },1000)
                    //                     }
                    //                 })
                    //                     .catch((error) => {
                    //                         console.log(error);
                    //                     });
                    //             }
                    //         }else{
                    //             console.log("Server Error")
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         console.log(error);
                    //     });
                    break;

                case "setHotelLock":
                    hotelLockSetForm.clear();
                    hotelLockSetForm.setValue({"hotelLockSetFormTip":"若不需設定大樓門鎖密碼請設定為空值或按清除","AG001":"0"});
                    axios.post(apiServer+"/accAndDev/loadHotelLockSet", {
                        itemId1: hotel
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                hotelLockSetForm.setValue(response.data[0]);
                                dhxHotelLockSetWindow1.show();
                            }
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });

        toolbar1.events.on("inputChange", function(id, newValue){
            console.log(id, newValue);
        });

        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        roomNumberListLayout.getCell("roomNumberListToolbar").attach(toolbar1);

        // let dhxTabbar = new DHXTabbar(dhxAccCtlTabLayout.getCell("accCtlSetToolbar"),{
        //     views: [
        //         {tab: "Password Management", id: "pwdMang"},
        //         {tab: "Door Lock Event Log", id: "doorLockEventLog"},
        //         {tab: "Charge Log", id: "chargeLog"},
        //     ],
        // });

        axios.post(apiServer+"/accAndDev/loadDoorLockStatusList", {
            itemId1: hotel
        },headers).then((response) =>{
            dhxDataview.data.removeAll();
            dhxDataview.data.parse(response.data);
        })
            .catch((error) => {
                console.log(error);
            });

        // axios.get(apiServer+"/accAndDev/type2",headers)
        //     .then((response) =>{
        //     console.log(response);
        //     // dhxDataview.data.removeAll();
        //     // dhxDataview.data.parse(response.data);
        // })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        const template = ({room, device, status, battery,name }) => (`
            <div style="margin: 10px 0">
                <h2><i class="fa-solid fa-lock" style="padding:5px; margin:3px 3px 3px 0px;"></i>房間: ${room}</h2>
                <div style="font-size: 1.2em"><span style="color: ${status == "ONLINE"?'green':'red'}"><i class="fa-solid fa-wifi" style="padding:5px; margin:3px 3px 3px 0px;"></i>&nbsp;狀態:${status}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style="color: ${battery <= 40?'red':'green'}"><i class="fa-solid fa-battery-full" style="padding:5px; margin:3px 3px 3px 0px;"></i>&nbsp;電量:${battery}%</span></div>
                <span>${name}</span>
            </div>
        `);

        let openEventGrid = new DHXGrid(null,{
            columns: doorLockEventLogGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers:{
                onclick:{

                }
            }
        });

        let dhxDataview = new DHXDataview(roomNumberListLayout.getCell("roomNumberList"), {
            itemsInRow: 3,
            gap: 10,
            css: "dhx_widget--bordered",
            template: template,
        });

        dhxDataview.events.on("click", function(id, e,room){
            console.log(id, e ,room);

            if(canEdit){
                axios.post(apiServer+"/accAndDev/loadOpenEventList", {
                    itemId1: id
                },headers).then((response) =>{

                    if(response.status === 200){
                        if(response.data.length != 0){
                            openEventGrid.data.removeAll();
                            openEventGrid.data.parse(response.data);

                            axios.post(apiServer+"/accAndDev/loadDoorLockStatusInfo", {
                                itemId1: id
                            },headers).then((response2) =>{

                                if(response2.status === 200){
                                    if(response2.data.length != 0){
                                        dhxDoorLockEventsWindow1.header.data.update("title", {value: `房間: ${response2.data[0].room}-${response2.data[0].name}`});
                                        dhxDoorLockEventsWindow1.show();
                                    }
                                }

                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    }

                })
                    .catch((error) => {
                        console.log(error);
                    });

            }else{
                DHXMessage({
                    text: "請先確認擁有編輯的權限",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            }
        });

        const dhxDoorLockEventsWindow1 = new DHXWindow({
            title: " ",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:800,
            height: 500,
            footer: true,
            css: "dhxDoorLockEventsWindow1",
        });

        dhxDoorLockEventsWindow1.footer.data.add({type:"spacer"});
        dhxDoorLockEventsWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));

        dhxDoorLockEventsWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLOSE":
                    dhxDoorLockWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        let dhxOpenEventListLayout = new DHXLayout(null,{
            rows:[
                {id: "openEventList"},
            ]
        });

        dhxOpenEventListLayout.getCell("openEventList").attach(openEventGrid);

        dhxDoorLockEventsWindow1.attach(dhxOpenEventListLayout);

        const dhxHotelLockSetWindow1 = new DHXWindow({
            title: "大樓門鎖密碼設定",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:400,
            height: 230,
            footer: true,
            css: "dhxHotelLockSetWindow1",
        });

        dhxHotelLockSetWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        dhxHotelLockSetWindow1.footer.data.add({type:"spacer"});
        dhxHotelLockSetWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxHotelLockSetWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxHotelLockSetWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLOSE":
                    dhxDoorLockWindow1.hide();
                    break;
                case "WINDOW_FOOT_CLEAR":
                    axios.post(apiServer+"/accAndDev/updateHotelLockSet", {
                        itemId1: hotel,itemId2: ''
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });
                                dhxHotelLockSetWindow1.hide();
                                hotelLockSetForm.clear();
                                hotelLockSetForm.setValue({"hotelLockSetFormTip":"若不需設定大樓門鎖密碼請設定為空值或按清除","AG001":"0"});
                            }
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    dhxDoorLockWindow1.hide();
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    axios.post(apiServer+"/accAndDev/updateHotelLockSet", {
                        itemId1: hotel,itemId2: hotelLockSetForm.getItem("AG100").getValue()
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });
                                dhxHotelLockSetWindow1.hide();
                                hotelLockSetForm.clear();
                                hotelLockSetForm.setValue({"hotelLockSetFormTip":"若不需設定大樓門鎖密碼請設定為空值或按清除","AG001":"0"});
                            }
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });

        const hotelLockSetForm = new DHXForm(null,{
            rows: hotelLockSetFormConfig
        });

        hotelLockSetForm.events.on("change", function(name, value) {
            if(isNeedFirstChangeEvent_doorLockSetForm === false){

            }else{
                switch (name){
                    case "AA003":
                        if(doorLockSetForm.getItem("AA003").getValue() != '0' && doorLockSetForm.getItem("AA003").getValue() != ''){
                            axios.post(apiServer+"/accAndDev/loadRoomList", {
                                itemId1: hotel,itemId2: doorLockSetForm.getItem("AA003").getValue()
                            },headers).then((response) =>{
                                doorLockSetForm.getItem("AA004").setOptions(response.data);
                                doorLockSetForm.getItem("AA004").enable();
                                doorLockSetForm.getItem("AA004").setValue("0");
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            doorLockSetForm.getItem("AA004").disable();
                            doorLockSetForm.getItem("AA004").setValue("0");
                        }
                        break;
                }
            }
        });

        dhxHotelLockSetWindow1.attach(hotelLockSetForm);

        // let dhxOpenEventListLayout = new DHXLayout(null,{
        //     rows:[
        //         {id: "openEventList"},
        //     ]
        // });

        // dhxOpenEventListLayout.getCell("openEventList").attach(openEventGrid);
        //
        // dhxDoorLockEventsWindow1.attach(dhxOpenEventListLayout);


        let deviceControlTabLayout2 = new DHXLayout(dhxAccCtlTabLayout.getCell("accCtlSetToolbar"),{
            rows:[
                {id:"doorLockSetToolbar", height: "content", gravity: false},
                {id: "doorLockSetGrid"},
            ]
        });

        let doorLockSetGrid = new DHXGrid(null,{
            columns: doorLockSetGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers:{
                onclick:{
                    doorLockSetGrid_edit:function(event,data){
                        if(canEdit){
                            isNeedFirstChangeEvent_doorLockSetForm = false;
                            axios.post(apiServer+"/accAndDev/loadDoorLockSetFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if (response.status === 200){
                                    if (response.data.length !== 0){
                                        axios.post(apiServer+"/accAndDev/loadRoomTypeList", {
                                            itemId1: hotel,
                                        },headers).then((response2) =>{
                                            if (response2.status === 200){
                                                if (response2.data.length !== 0){

                                                    doorLockSetForm.getItem("AA003").setOptions(response2.data);
                                                    axios.post(apiServer+"/accAndDev/loadRoomList", {
                                                        itemId1: hotel,itemId2: response.data[0].AA003
                                                    },headers).then((response3) =>{
                                                        if (response3.status === 200){
                                                            if (response3.data.length !== 0){
                                                                doorLockSetForm.getItem("AA004").setOptions(response3.data);
                                                                doorLockSetForm.setValue(response.data[0]);
                                                                doorLockSetForm.getItem("AA004").enable();
                                                                dhxDoorLockWindow1.show();
                                                                isNeedFirstChangeEvent_doorLockSetForm = true;
                                                            }else{
                                                            }
                                                        }else{

                                                        }
                                                    })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        });
                                                }else{
                                                }
                                            }else{

                                            }

                                        })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                    }else{

                                    }
                                }else{

                                }

                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }


                    },
                    doorLockSetGrid_delete:function(event,data){

                        if(canDelete){
                            axios.post(apiServer+"/accAndDev/loadDoorLockSetFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if (response.status === 200){
                                    if (response.data.length !== 0){
                                        doorLockSetDeleteForm.setValue(response.data[0]);
                                        doorLockSetDeleteForm.getItem("deleteFormName").setValue(data.row.doorLock_room);
                                        doorLockSetDeleteForm.getItem("AA112").setValue("");
                                    }else{

                                    }
                                }else{

                                }

                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxDoorLockWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                }
            }
        });

        axios.post(apiServer+"/accAndDev/loadDoorLockSetGrid",{itemId1:hotel},headers)
            .then((response) =>{
                if(response.status === 200){
                    doorLockSetGrid.data.removeAll();
                    doorLockSetGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const toolbar2 = new DHXToolbar(null, {
        });
        toolbar2.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    doorLockSetForm.clear();
                    doorLockSetForm.setValue({"AA001": "0","AA002":hotel});
                    doorLockSetForm.clear("validation");

                    axios.post(apiServer+"/accAndDev/loadRoomTypeList", {
                        itemId1: hotel,
                    },headers).then((response) =>{
                        doorLockSetForm.getItem("AA003").setOptions(response.data);
                        doorLockSetForm.getItem("AA003").setValue("0");
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    isNeedFirstChangeEvent_doorLockSetForm = true;
                    dhxDoorLockWindow1.show();
                    break;
                case "reload":
                    axios.post(apiServer+"/accAndDev/loadDoorLockSetGrid",{itemId1:hotel},headers)
                        .then((response) =>{
                            if(response.status === 200){
                                doorLockSetGrid.data.removeAll();
                                doorLockSetGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar2.data.parse(JSON.parse(JSON.stringify(toolbar2Config)));

        // dhxTabbar.getCell("doorLockSet").attach(deviceControlTabLayout2);
        deviceControlTabLayout2.getCell("doorLockSetToolbar").attach(toolbar2);
        deviceControlTabLayout2.getCell("doorLockSetGrid").attach(doorLockSetGrid);

        const dhxDoorLockWindow1 = new DHXWindow({
            title: "新增門鎖視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 450,
            width:450,
            footer: true,
            css: "dhxDoorLockWindow1"
        });

        dhxDoorLockWindow1.footer.data.add({type:"spacer"});
        dhxDoorLockWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxDoorLockWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClear)));
        dhxDoorLockWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxDoorLockWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    doorLockSetForm.clear();
                    doorLockSetForm.setValue({"AA001": "0","AA002":hotel,"AA003":"0","AA004":"0"});
                    doorLockSetForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(doorLockSetForm.getItem("AA003").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇房型",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(doorLockSetForm.getItem("AA004").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇房間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(doorLockSetForm.getItem("AA109").getValue() === ""){
                        DHXMessage({
                            text: "請選擇門鎖類別",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(doorLockSetForm.getItem("AA101").getValue() === ""){
                        DHXMessage({
                            text: "請填入門鎖ID",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/accAndDev/checkDoorLockId",
                        doorLockSetForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result != 0){
                            DHXMessage({
                                text: "門鎖編號重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });

                        }else{
                            axios.post(apiServer+"/accAndDev/updateDoorLockSetFormData",
                                doorLockSetForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result == 1){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    doorLockSetForm.clear();
                                    doorLockSetForm.setValue({"AA001": "0","AA002":hotel});
                                    doorLockSetForm.clear("validation");

                                    axios.post(apiServer+"/accAndDev/loadDoorLockSetGrid",{itemId1:hotel},headers)
                                        .then((response) =>{
                                            if(response.status === 200){
                                                doorLockSetGrid.data.removeAll();
                                                doorLockSetGrid.data.parse(response.data);
                                                dhxDoorLockWindow1.hide();
                                            }else{
                                                console.log("Server Error")
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });

                                    axios.post(apiServer+"/accAndDev/loadDoorLockStatusList", {
                                        itemId1: hotel
                                    },headers).then((response) =>{
                                        console.log(response);
                                        dhxDataview.data.removeAll();
                                        dhxDataview.data.parse(response.data);
                                    })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }else{
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxDoorLockWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const doorLockSetForm = new DHXForm(null,{
            rows: doorLockSetFormConfig
        });

        doorLockSetForm.events.on("change", function(name, value) {
            if(isNeedFirstChangeEvent_doorLockSetForm === false){

            }else{
                switch (name){
                    case "AA003":
                        if(doorLockSetForm.getItem("AA003").getValue() != '0' && doorLockSetForm.getItem("AA003").getValue() != ''){
                            axios.post(apiServer+"/accAndDev/loadRoomList", {
                                itemId1: hotel,itemId2: doorLockSetForm.getItem("AA003").getValue()
                            },headers).then((response) =>{
                                doorLockSetForm.getItem("AA004").setOptions(response.data);
                                doorLockSetForm.getItem("AA004").enable();
                                doorLockSetForm.getItem("AA004").setValue("0");
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            doorLockSetForm.getItem("AA004").disable();
                            doorLockSetForm.getItem("AA004").setValue("0");
                        }
                        break;
                }
            }
        });

        dhxDoorLockWindow1.attach(doorLockSetForm);

        const dhxDoorLockWindow2 = new DHXWindow({
            title: "刪除門鎖視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "dhxDoorLockWindow2",
        });

        dhxDoorLockWindow2.footer.data.add({type:"spacer"});
        dhxDoorLockWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxDoorLockWindow2.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxDoorLockWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(doorLockSetDeleteForm.getItem("AA112").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/accAndDev/deleteDoorLockSet",
                        doorLockSetDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/accAndDev/loadDoorLockSetGrid",{itemId1:hotel},headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        doorLockSetGrid.data.removeAll();
                                        doorLockSetGrid.data.parse(response.data);
                                        dhxDoorLockWindow2.hide();
                                        doorLockSetDeleteForm.clear();
                                        doorLockSetDeleteForm.clear("validation");
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxDoorLockWindow2.hide();
                    doorLockSetDeleteForm.clear();
                    doorLockSetDeleteForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let doorLockSetDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"doorLockSetDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "doorLockSetDeleteForm"},
            ]
        });

        const doorLockSetDeleteForm = new DHXForm(null,{
            rows: deleteFormConfig
        });

        dhxDoorLockWindow2.attach(doorLockSetDeleteFormLayout);
        doorLockSetDeleteFormLayout.getCell("doorLockSetDeleteForm").attach(doorLockSetDeleteForm);

        return () =>{
            dhxAccCtlTabLayout && dhxAccCtlTabLayout.destructor();
        }

    }, [canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
            <MDBox py={3}>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <CardHeader title={"門鎖設定"}/>
                            <MDBox py={1} px={1}>
                                <div ref={DHXAccCtlTabLayout} style={{height:"800px"}}></div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default AccessControlBasicSetting;